<template>
  <div class="shop-settings">
    <div class="page-header">
      <h1>{{ $t('Settings') }} > {{ $t('Shop Settings') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Shop Settings') }}</h2>
      </div>
      <div class="form-content">
        <el-form ref="form" :model="form">
          <el-form-item :label="$t('Shop Name (En)')" style="max-width: 400px">
            <el-input v-model="form.nameEn"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Shop Name (Chinese)')" style="max-width: 400px">
            <el-input v-model="form.nameZh"></el-input>
          </el-form-item>

          <div class="shop-image-wrapper">
            <el-form-item class="shop-logo" :label="$t('Shop Logo')">
              <el-upload
                action="https://jsonplaceholder.typicode.com/posts/"
                list-type="picture-card">
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="Shop Banner">
              <el-upload
                action="https://jsonplaceholder.typicode.com/posts/"
                list-type="picture-card">
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </div>

          <el-form-item class="update-button">
            <el-button type="primary">Update</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ShopSettings',
  mounted(){
    this.uploadUrl = `${this.apiUrl}/setting/file`;
  },
  data(){
    const form = {
      nameEn: 'Test Shop',
      nameZh: 'Test Shop',
      logo: '',
      banner: '',
    };
    return {
      form,
    }
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.shop-settings{
  .form-content{
    padding-left: 20px;
    .shop-image-wrapper{
      display: flex;
      .shop-logo{
        margin-right: 50px;
      }
    }
    .el-form-item__label{
      float: none;
    }
    .update-button{
      .el-form-item__content{
        text-align: right;
      }
    }
  }
}
</style>
